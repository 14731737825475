<template>
  <div style="margin: 20px">
    <div class="flex justify-between">
      <p class="font-bold text-lg" style="margin-left: 10px">Category List</p>
    </div>

    <div class="bg-white py-3 px-4 mt-3" style="margin: 12px 10px 0; border-radius: 5px">
      <p class="text-sm">
        This page lists all the categories currently available at EAE. You can
        also click to see more details and descriptions of a specific category.
      </p>
    </div>

    <!-- <div class="flex py-3 px-4 mt-3 gap-3">
      <div
        :class="[
          {
            active: filter === 'ddl',
          },
          'filter-item',
        ]"
        @click="doRequestData('ddl')"
      >
        Sort by Deadline
      </div>
      <div
        :class="[
          {
            active: filter === 'name',
          },
          'filter-item',
        ]"
        @click="doRequestData('name')"
      >
        Sort by Category Name
      </div>
    </div> -->
    <el-form ref="form" :model="form" label-width="80px" style="margin-top: 12px">
      <el-form-item label="Sort by" style="margin-bottom: 12px">
        <el-radio-group v-model="form.sort">
          <!-- <el-radio label="ddl">Deadline</el-radio> -->
          <el-radio label="name">Name</el-radio>
          <el-radio label="favorite">Favorite</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>

    <div class="flex gap-3" style="flex-wrap: wrap;">
      <div class="category cursor-pointer" v-for="(item, index) in dataList" :key="index" @click="handleCategory(item)">
        <ActivityCategory :info="item" :index="index" :showShareBtn="type" @showShare="showShareView(item)"
          @success="doRequestData(filter)" />
      </div>
    </div>

    <el-dialog :visible.sync="showCategoryDialog" title="Category Details">
      <p class="text-xl font-bold" style="color: #ff8e3b">
        {{ categoryName }}
      </p>
      <div class="content rich-text" v-html="categoryContent"></div>
    </el-dialog>

    <el-dialog :visible.sync="showShareDialog" :modal="true" :show-close="false" :modal-append-to-body="true"
      custom-class="showShareCourseDialogStyle">
      <div style="position: relative; width: 100%; text-align: left">
        <div class="shareDialogContent">
          <div style="
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            ">
            <p class="common-title">Share this Category</p>
            <img src="../../assets/image/icon_close.png" @click="showShareDialog = false" style="
                cursor: pointer;
                width: 18px;
                height: 18px;
                position: absolute;
                right: 24px;
              " />
          </div>

          <p style="
              margin-top: 32px;
              color: #333333;
              font-size: 16px;
              margin-bottom: 32px;
            ">
            Share this Category with your student.
          </p>
          <div style="margin-top: 9px; display: flex">
            <p class="share-text">{{ share_link_url }}</p>
            <p class="share-copy-link" @click="doShare" :data-clipboard-text="share_link_url">
              Copy the URL
            </p>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getTypes, getContactTypes } from "../../api/eae";
import ActivityCategory from "../../components/AdvisorCategory.vue";
import { getUserType, getAccountId, getUserId } from "../../utils/store.js";
import Clipboard from "clipboard";

export default {
  name: "index",
  components: {
    ActivityCategory,
  },
  data() {
    return {
      dataList: [],
      showCategoryDialog: false,
      showShareDialog: false,
      categoryContent: "",
      categoryName: "",
      filter: "name",
      share_link_url: "",
      type: getUserType(),
      form: {
        sort: "name",
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    "form.sort": {
      handler(newVal, oldVal) {
        this.doRequestData(newVal);
      },
    },
  },
  methods: {
    doRequestData(type) {
      this.filter = type;
      this.fetchData();
    },
    async fetchData() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      if (getUserType() == "advisor") {
        try {
          const res = await getContactTypes(getUserId());
          let result = [
            ...res.data.data.filter((item) => item.name !== "Others"),
            ...res.data.data.filter((item) => item.name === "Others"),
          ];
          this.filterData(result);
        } catch (error) {
        } finally {
          loading.close();
        }
      } else {
        try {
          const res = await getTypes();
          let result = [
            ...res.data.data.filter((item) => item.name !== "Others"),
            ...res.data.data.filter((item) => item.name === "Others"),
          ];
          this.filterData(result);
        } catch (error) {
        } finally {
          loading.close();
        }
      }
    },
    filterData(result) {
      const times = result
        .filter(
          (item) =>
            item.name !== "Others" &&
            !this.checkSubmissionEnded(item.submission_ddl)
        )
        .map((item) => item.submission_ddl)
        .sort((a, b) => a - b);
      const ddlTime = times.length > 0 ? times[0] : 0;
      if (this.filter == "ddl") {
        let openList = result.filter(
          (item) =>
            item.name !== "Others" &&
            !this.checkSubmissionEnded(item.submission_ddl)
        );
        let closeList = result.filter(
          (item) =>
            item.name !== "Others" &&
            this.checkSubmissionEnded(item.submission_ddl)
        );
        let otherList = result.filter((item) => item.name === "Others");
        let list1 = openList.sort((a, b) => {
          if (a.submission_ddl && b.submission_ddl) {
            if (a.submission_ddl > b.submission_ddl) {
              return 1;
            }
          }
          return -1;
        });
        let list2 = closeList.sort((a, b) => {
          if (a.submission_ddl && b.submission_ddl) {
            if (a.submission_ddl > b.submission_ddl) {
              return 1;
            }
          }
          return -1;
        });
        this.dataList = list1.concat(list2, otherList).map((item) => ({
          ...item,
          nearBy: item.submission_ddl === ddlTime,
        }));
      } else if (this.filter === "favorite") {
        this.dataList = [...this.dataList.filter((item) => item.collect)].map(
          (item) => ({
            ...item,
            nearBy: item.submission_ddl === ddlTime,
          })
        );
      } else {
        this.dataList = result.map((item) => ({
          ...item,
          nearBy: item.submission_ddl === ddlTime,
        }));
      }
    },
    handleCategory(item) {
      if (getUserType() === "advisor") {
        this.$router.push({
          path: "/home/categoryDetail",
          query: {
            id: item._id.$id,
          },
        });
      } else {
        this.showCategoryDialog = true;
        this.categoryContent = item.instructions;
        this.categoryName = item.name;
      }
    },

    showShareView(item) {
      // http://localhost:8809/#/contactappform?account_id=5be93f80a4640b531d8b462e&contact_id=60ac90b6a4640ba65a8b4570&category_id=642e635ca4640be0188b456f

      this.share_link_url =
        "https://my.eae.international/#/contactappform?account_id=" +
        getAccountId() +
        "&contact_id=" +
        getUserId() +
        "&category_id=" +
        item._id.$id;

      this.showShareDialog = true;
    },

    doShare() {
      const clipboard = new Clipboard(".share-copy-link");
      clipboard.on("success", () => {
        this.showShareDialog = false;
        this.$message.success("URL copied");
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        this.$message.error("Copy failed");
        clipboard.destroy();
      });
    },

    checkSubmissionEnded(submission_ddl) {
      let todayStartTime = new Date(new Date().toLocaleDateString()).getTime();
      let startTime = new Date(todayStartTime - 1).getTime();
      if (startTime > submission_ddl * 1000) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.category {}

.content {
  white-space: pre-line;
  color: rgb(102, 102, 102);
  word-break: keep-all;
}

.filter-item {
  padding: 6px 18px;
  background: #eaeaea;
  border-radius: 5px;
  font-weight: 400;
  color: #999999;
  font-size: 15px;
  cursor: pointer;

  &.active {
    font-weight: bold;
    color: #ff6450;
    background: #ffe6e3;
  }
}

.share-copy-link {
  cursor: pointer;
  text-align: center;
  line-height: 48px;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
  width: 120px;
  height: 48px;
  background: #ff6450;
  border-radius: 0px 5px 5px 0px;
}

.share-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  background-color: #f5f5f5;
  height: 48px;
  padding: 0px 18px;
  line-height: 48px;
  outline: none;
  border-radius: 5px 0px 0px 5px;
  box-sizing: border-box;
  color: #666666;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
}

.shareDialogContent {
  padding: 0 24px;
  box-sizing: border-box;
  width: 100%;
  height: 240px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
}

.share-container {
  cursor: pointer;
  width: 140px;
  height: 40px;
  background: #f5af3c;
  border-radius: 5px 5px 5px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &.signed-up {
    background-color: #cccccc;
    cursor: default;
  }
}

.share-btn {
  padding: 5px 12px;
  background: #50559b;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;

  .share-link {
    font-size: 14px;
    margin-left: 10px;
  }
}

.share-link {
  margin-left: 20px;
  font-size: 18px;
  font-family: CenturyGothicbold;
  font-weight: normal;
  color: #ffffff;

  &.signed-up {
    background-color: #cccccc;
  }
}
</style>

<style lang="scss">
.rich-text {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "PT Sans", "SF UI Display", ".PingFang SC", "PingFang SC",
      "Neue Haas Grotesk Text Pro", "Arial Nova", "Segoe UI", "Microsoft YaHei",
      "Microsoft JhengHei", "Helvetica Neue", "Source Han Sans SC",
      "Noto Sans CJK SC", "Source Han Sans CN", "Noto Sans SC",
      "Source Han Sans TC", "Noto Sans CJK TC", "Hiragino Sans GB", sans-serif;
    text-rendering: optimizelegibility;
    margin-bottom: 1em;
    font-weight: bold;
    line-height: 1.8rem;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }
}
</style>
